import Parse from 'parse'
import moment from 'moment-timezone'

moment.locale('es')
moment.tz.setDefault('America/Mexico_City')

import Category from './Category'

export default class Book extends Parse.Object {

  subcategories = []

  constructor() {
    super('Book')
  }

  get barcode() {
    return this.get('barcode')
  }

  set barcode(value) {
    this.set('barcode', value)
  }

  get enabled() {
    return this.get('enabled')
  }

  set enabled(value) {
    this.set('enabled', value)
  }

  get author() {
    return this.get('author')
  }

  set author(value) {
    this.set('author', value)
  }

  get url() {
    return this.get('url')
  }

  set url(value) {
    this.set('url', value)
  }

  get digitalPrice() {
    return this.get('digitalPrice')
  }

  set digitalPrice(value) {
    this.set('digitalPrice', value)
  }

  get physicalPrice() {
    return this.get('physicalPrice')
  }

  set physicalPrice(value) {
    this.set('physicalPrice', value)
  }

  get isbn() {
    return this.get('isbn')
  }

  set isbn(value) {
    this.set('isbn', value)
  }

  get cover() {
    return this.get('cover') ? this.get('cover').url() : null
  }

  set cover(value) {
    this.set('cover', value)
  }

  get title() {
    return this.get('title')
  }

  set title(value) {
    this.set('title', value)
  }

  get digitalPhysicalPrice() {
    return this.get('digitalPhysicalPrice')
  }

  set digitalPhysicalPrice(value) {
    this.set('digitalPhysicalPrice', value)
  }

  get description() {
    return this.get('description')
  }

  set description(value) {
    this.set('description', value)
  }

  get category() {
    return this.get('category')
  }

  set category(value) {
    this.set('category', value)
  }

  get createdAt() {
    return moment(this.get('createdAt')).format('dddd DD MMMM, YYYY')
  }

  get needsFileProcessing() {
    return this.get('needsFileProcessing')
  }

  get isFileReady() {
    return this.get('fileReady')
  }

  get password() {
    return this.get('password')
  }

  static count() {
    return new Parse.Query(this).count()
  }

  static load(limit = 500, skip = 0, category) {
    let query = new Parse.Query(this)
      .limit(limit)
      .skip(skip)
      .descending('createdAt')
      .withCount()

    if (category != null) {
      let filterCategory = Category.createWithoutData(category)
      query = query.equalTo('category', filterCategory)
    }

    return query.find()
  }

  static getObject(id) {
    return new Parse.Query(this).include(['category']).get(id)
  }

  dump() {
    console.log(this)
  }
}