<template lang="pug">
  validation-observer(v-slot="{invalid, handleSubmit}")
    b-form(@submit.prevent="handleSubmit(saveEmail)")
      .login-body-container.d-flex.align-items-center.justify-content-center.flex-column
        b-form-row.mb-5.w-100.justify-content-center
          b-col.text-center.font-weight-bold(cols="4")
            p.mb-0 Bienvenido, introduce 
            p.mb-0 tu correo electrónico
          b-col(cols="4")
            validation-provider(name="correo electrónico", rules="required|email", v-slot="{ errors }")
              b-form-input.w-100.text-center(placeholder="Escribe tu correo electrónico aquí", type="email", v-model="email", :state="errors.length ? false : null", autofocus)
        .text-center 
          b-button(variant="gradient-primary", :disabled="invalid", type="submit") Continuar
</template>

<script>
export default {
  data () {
    return {
      email: ''
    }
  },
  methods: {
    saveEmail () {
      this.$store.commit('setEmail', this.email)
      this.$router.push({name: 'Login/Password'})
    }
  }
}
</script>

<style scoped lang="scss">
  .login-body-container {
    width: 100%;
    height: calc(100vh - 56px);
  }
</style>