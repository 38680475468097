var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"py-5"},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.Institute.name))])]),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"text-reset",attrs:{"variant":"link","to":{ name: "Escuelas" }}},[_c('arrow-left-circle-icon'),_c('span',{staticClass:"ml-2"},[_vm._v("Regresar")])],1)],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('p',{staticClass:"mb-2 font-weight-bold"},[_vm._v("Detalle de escuela")]),_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('b-form-row',[_c('b-col',{attrs:{"cols":"auto"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Nombre:")])]),(!_vm.edit)?_c('b-col',[_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(_vm._s(_vm.Institute.name))])]):_c('b-col',[_c('b-form-input',{attrs:{"placeholder":"Nombre"},model:{value:(_vm.Institute.name),callback:function ($$v) {_vm.$set(_vm.Institute, "name", $$v)},expression:"Institute.name"}})],1)],1)],1)],1),_c('div',{staticClass:"text-right mt-5"},[_c('b-button',{staticClass:"px-5",attrs:{"variant":"light"},on:{"click":function($event){_vm.edit = true}}},[_vm._v("Editar")]),_c('b-button',{staticClass:"ml-3 px-5",attrs:{"variant":"light"},on:{"click":_vm.saveInstitute}},[_vm._v("Guardar cambios")])],1)],1)],1)],1),_c('b-card',[_c('b-row',[_c('b-col',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Ventas grupales")])]),_c('b-col',{attrs:{"cols":"auto"}})],1),_c('b-table',{staticClass:"card-table",attrs:{"hover":"","fields":_vm.Fields,"items":_vm.Items,"responsive":"","show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('span',{staticClass:"text-secondary"},[_vm._v("No hay datos para mostrar")])]},proxy:true},{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold text-underline"},[_vm._v(_vm._s(item.id))])]}},{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$moment(item.date).format("DD/MM/YY")))])]}},{key:"cell(booksIncluded)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"text-reset font-weight-bold",attrs:{"variant":"link"}},[_vm._v("Ver lista")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"text-reset font-weight-bold",attrs:{"variant":"link"},on:{"click":function($event){_vm.deleteBatchId = item.saleId}}},[_vm._v("Eliminar")])]}}])})],1),(!_vm.paginationLoading)?_c('b-pagination',{attrs:{"total-rows":_vm.paginationCount,"per-page":_vm.paginationLimit},on:{"change":_vm.loadData},model:{value:(_vm.paginationPage),callback:function ($$v) {_vm.paginationPage=$$v},expression:"paginationPage"}}):_vm._e(),_c('full-modal',{attrs:{"visible":_vm.deleteBatchId != null},on:{"cancel":function($event){_vm.deleteBatchId = null}}},[_c('b-container',[_c('h4',{staticClass:"text-center"},[_vm._v("¿Estás seguro de eliminar la venta grupal?, los usuarios que hayan usado el código ya no tendrán acceso al contenido relacionado")]),_c('div',{staticClass:"text-center mt-5"},[_c('b-button',{attrs:{"variant":"gradient-primary","disabled":_vm.loading},on:{"click":_vm.deleteBatch}},[_vm._v("Eliminar")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }