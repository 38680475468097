import Parse from 'parse'
import moment from 'moment-timezone'

moment.locale('es')
moment.tz.setDefault('America/Mexico_City')

export default class Order extends Parse.Object {

  subcategories = []

  constructor() {
    super('Order')
  }

  get trackingGuide() {
    return this.get('trackingGuide')
  }

  get additionalReference() {
    return this.get('additionalReference')
  }

  get total() {
    return this.get('total')
  }

  get package() {
    return this.get('package')
  }

  get packageString() {
    switch(this.package){
      case 0: return 'Digital'
      case 1: return 'Físico'
      case 2: return 'Físico y digital'
    }
  }

  get paymentMethod() {
    return this.get('paymentMethod')
  }

  get book() {
    return this.get('book')
  }

  get user() {
    return this.get('user')
  }

  get card() {
    return this.get('card')
  }

  get address() {
    return this.get('address')
  }

  get status() {
    switch(this.get('status')) {
      case 'pending_payment': return 'Pago pendiente'
      case 'pending_delivery': return 'Envío pendiente'
      case 'delivered': return 'Enviado'
      default: return '-'
    }
  }

  get paymentId() {
    return this.get('paymentOrderId')
  }

  get createdAt() {
    return moment(this.get('createdAt')).format('DD/MM/YYYY')
  }

  static count() {
    return new Parse.Query(this).count()
  }

  static load(limit = 500, skip = 0, history = false) {
    let query = new Parse.Query(this)
      .limit(limit)
      .skip(skip)
      .include(['book', 'user'])
      .descending('createdAt')
      .withCount()

    if(!history)
      query.equalTo('status', 'pending_delivery')

    return query.find()
  }

  static loadCountByBook(id) {
    let book = Parse.Object
      .extend("Book")
      .createWithoutData(id)

    return new Parse.Query(this)
      .equalTo('book', book)
      .count()
  }

  static getObject(id) {
    return new Parse.Query(this).get(id)
  }

  dump() {
    console.log(this)
  }
}