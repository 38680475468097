import axios from 'axios'

let client = axios.create({
    baseURL: process.env.VUE_APP_REPORT_URL,
    headers: {
        'X-Parse-Application-Id': process.env.VUE_APP_ID_APP,
        'X-Parse-REST-API-Key': process.env.VUE_APP_KEY_JS
    },
    responseType: 'blob'
})

let endpoints = {
    balance: '/balance_report',
    balance_new_sales:'/balance_new_sales',
    balance_period: '/balance_period',
    individual_sales: '/individual_sales',
    group_sales: '/group_sales'
}

export default class ReportsApi {
    static async balance() {
        try {
            let { data } = await client.get(endpoints.balance)

            return data
        } catch (error) {
            throw new Error("Cannot create report " + error.message)
        }
    }

    static async newSales() {
        try {
            let { data } = await client.get(endpoints.balance_new_sales)

            return data
        } catch (error) {
            throw new Error("Cannot create report " + error.message)
        }
    }

    static async balancePeriod(startDate, finishDate) {
        try {
            let { data } = await client.get(endpoints.balance_period, {
                params: { startAt: startDate, finishAt: finishDate }
            })

            return data
        } catch (error) {
            throw new Error("Cannot create report " + error.message)
        }
    }

    static async individualSales() {
        try {
            let { data } = await client.get(endpoints.individual_sales)

            return data
        } catch (error) {
            throw new Error("Cannot create report " + error.message)
        }
    }

    static async groupSales() {
        try {
            let { data } = await client.get(endpoints.group_sales)

            return data
        } catch (error) {
            throw new Error("Cannot create report " + error.message)
        }
    }
}