<template lang="pug">
  b-container.py-5
    b-row.mb-3
      b-col
        h5.font-weight-bold Colegio Alemán
      b-col(cols="auto")
        b-button.text-reset(variant="link", :to="{name: 'Escuelas/VentaGrupal'}")
          arrow-left-circle-icon
          span.ml-2 Regresar

    b-card 
      p.font-weight-bold {{ isNew ? 'Resumen de venta grupal' : 'Detalle de venta grupal' }}
      b-row.mb-5
        b-col(cols="6")
          b-row
            b-col.mb-3(cols="12", v-for="item in summary")
              b-form-row.align-items-center
                b-col(cols="5")
                  p.mb-0 {{ item.value }}
                b-col(cols="7")
                  p.mb-0.font-weight-bold {{ Array.isArray(item.text) ? item.text.map(x => x.text).join(', ') : item.text }}

        b-col(cols="6")
          b-form-row
            b-col(cols="5")
              p.mb-0 Lista de alumnos:
            b-col(cols="7")
              p(v-for="i in 10") alumno@aleman.edu.mx

      .text-right(v-if="isNew")
        b-button(variant="gradient-primary", @click="successModal = true;") Confirmar y enviar correos de bienvenida
      .text-right(v-else)
        b-button(variant="danger") Dar de baja venta

    full-modal(:visible="successModal", :cancelable="false")
      b-container
        b-row
          b-col.font-weight-bold.text-center
            p La venta grupal ha sido dada de alta correctamente.
            b-button(variant="gradient-primary", :to="{name: 'Escuelas'}") Continuar
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {en, es} from 'vuejs-datepicker/dist/locale'

export default {
  data () {
    return {
      successModal: false,
      summary: [
        {
          value: 'Colegio',
          text: 'Colegio Alemán'
        },
        {
          value: 'Nivel escolar',
          text: 'Primaria'
        },
        {
          value: 'Número de alumnos',
          text: '60'
        },
        {
          value: 'Comisión',
          text: '10%'
        },
        {
          value: 'Lista de correos',
          text: 'lista_correos_2020.csv'
        },
        {
          value: 'Fecha de vencimiento',
          text: '01/02/2021'
        },
        {
          value: 'Libros incluídos',
          text: [
            {
              value: 'one',
              text: 'One'
            },
            {
              value: 'three',
              text: 'Three'
            }
          ]
        },
        {
          value: 'Subtotal',
          text: '$xx.xx'
        },
        {
          value: 'Comisión',
          text: '$xx.xx'
        },
        {
          value: 'Total',
          text: '$xx.xx'
        },
      ]
    }
  },
  computed: {
    isNew () {
      return !this.$route.params.ventagrupal_id
    }
  }
}
</script>

<style>

</style>