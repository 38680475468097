<template lang="pug">
  b-container
    b-navbar(variant="white", type="light")
      b-navbar-brand
        logo
    router-view
</template>

<script>
export default {
  components: { 
    'logo': () => import('@/components/Logo.vue')
  }
}
</script>