//FIXME: Move imports on top, external modules first 
import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'

import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'

import Parse from './parse'
Vue.prototype.$parse = Parse

import store from './store'

import '@/assets/theme.scss'

Vue.config.productionTip = false

import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import es from 'vee-validate/dist/locale/es.json';
localize('es', es)

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

import FeatherWrapper from 'vue-feather-icons-wrapper'
Vue.use(FeatherWrapper)

import FullModal from '@/components/FullModal.vue'
Vue.component('full-modal', FullModal)

import Moment from 'moment-timezone'
Moment.locale('es')
Vue.prototype.$moment = Moment

import reportsApi from './api/reports-api'
Vue.prototype.$reports = reportsApi

Vue.prototype.$notify = new Vue()

//TODO: Move to mixins folder
Vue.mixin({
  data: () => ({
    paginationLimit: 50,
    paginationCount: 0,
    paginationPage: 1,
    paginationLoading: false,
    fetchedUser: false
  }),
  methods: {
    resetPagination() {
      this.paginationPage = 0
      this.paginationCount = 0
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
      } catch (e) {
        console.log(e)
      }
    },
    showInfo(message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster: 'b-toaster-bottom-center',
        solid: true,
        variant: 'info'
      })
    },
    showSuccess(message, title) {
      this.$bvToast.toast(message, {
        title,
        toaster: 'b-toaster-bottom-center',
        solid: true,
        variant: 'success'
      })
    },
    showError(message, title = 'Ocurrio un error') {
      this.$bvToast.toast(message, {
        title,
        toaster: 'b-toaster-bottom-center',
        solid: true,
        variant: 'danger'
      })
    }
  }
})

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
