<template lang="pug">
  b-modal(:visible="visible", size="xl", scrollable, centered, no-fade, no-close-on-backdrop, hide-header, hide-footer, modal-class="full")
    .text-right.mb-5.p-3(style="position:fixed;top:0;left:0;right:0;", v-if="cancelable")
      b-button.text-reset.text-sm.font-weight-bold(variant="link", @click="$emit('cancel')") Cancelar
    slot
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    cancelable: {
      type: Boolean,
      default: true
    },
  }
}
</script>