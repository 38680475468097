import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBDKftywV3VyvUmRokVvg5Bw-B4Et539Xk",
  authDomain: "lucero-lozano-app.firebaseapp.com",
  databaseURL: "https://lucero-lozano-app.firebaseio.com",
  projectId: "lucero-lozano-app",
  storageBucket: "lucero-lozano-app.appspot.com",
  messagingSenderId: "170223158919",
  appId: "1:170223158919:web:c92d98da2142f9849dda22"
};

firebase.initializeApp(firebaseConfig);

export const strg = firebase.storage();