<template lang="pug">
b-container.py-5
  b-row.mb-3
    b-col
      h5.font-weight-bold Administradores
  b-card 
    b-table.card-table.mb-0(hover, :fields='admin_fields', :items='admins', responsive, show-empty)
      template(#empty)
        p.text-center.mb-0.text-secondary No hay datos para mostrar

  b-row.mb-3
    b-col
      h5.font-weight-bold {{ showTeachers ? "Maestros" : "Usuarios" }}
      b-button(variant='gradient-primary', @click='showTeachers = !showTeachers') Mostrar {{ showTeachers ? "usuarios" : "maestros" }}
    b-col(cols='auto')
      b-button.text-reset(variant='link', @click='exportUserList') Exportar lista de {{ showTeachers ? "maestros" : "usuarios" }}

  b-card 
    b-row.mb-3
      b-col(cols='7')
        p.mb-2.font-weight-bold {{ showTeachers ? "Maestros" : "Usuarios" }} registrados
        b-button.text-reset.text-underline(variant='link', @click='copyEmails') Copiar correos electrónicos
          b-spinner.ml-2(small, variant='primary', v-if='copying')
      //- b-col(cols="5")
      //-   b-form-row.align-items-center
      //-     b-col(cols="auto")
      //-       span Ordenar por:
      //-     b-col
      //-       b-form-select(:options="filters", v-model="orderFilter", @change="loadUsers")
    b-table.card-table.mb-0(hover, :fields='fields', :items='showTeachers ? teachers : users', responsive, show-empty)
      template(#empty)
        p.text-center.mb-0.text-secondary No hay datos para mostrar
      template(#cell(antiquity)='{item}')
        span.font-weight-bold {{ $moment(item.antiquity).format("DD/MM/YY") }}
      template(#cell(total)='{item}')
        span.font-weight-bold {{ item.total }}
      template(#cell(status)='{item}')
        span.font-weight-bold {{ item.status }}
      template(#cell(email)='{item}')
        b-link.font-weight-bold(:href='`mailto:${item.email}`') {{ item.email }}
      template(#cell(actions)='{item}')
        b-button.text-reset.font-weight-bold.text-underline(variant='link', @click='$router.push({ name: "Usuarios/Usuario", params: { usuario_id: item.id } })') Ver detalles

  b-pagination(v-model='paginationPage', :total-rows='paginationCount', :per-page='paginationLimit', @change='loadUsers', v-if='!paginationLoading')

  full-modal(:visible='showMessageModal', @cancel='showMessageModal = false')
    b-container
      validation-observer(v-slot='{invalid, handleSubmit}')
        b-form(@submit.prevent='handleSubmit(sendMailToAudience)')
          b-form-row.mb-5.justify-content-center
            b-col.text-center.font-weight-bold(cols='5')
              p.mb-0 Asunto del mensaje:
            b-col(cols='5')
              validation-provider(name='asunto', rules='required|min:1', v-slot='{ errors }')
                b-form-input.w-100.text-center.mb-2(placeholder='Asunto del mensaje', v-model='subject', :state='errors.length ? false : null')
          b-form-row.mb-5.justify-content-center
            b-col.text-center.font-weight-bold(cols='5')
              p.mb-0 Mensaje:
            b-col(cols='5')
              validation-provider(name='mensaje', rules='required|min:1', v-slot='{ errors }')
                b-form-input.w-100.text-center.mb-2(placeholder='Mensaje', v-model='message', :state='errors.length ? false : null')
          .text-center 
            b-button(variant='gradient-primary', :disabled='invalid', type='submit') Enviar
</template>

<script>
import Parse from 'parse'
import User from '@/parse/models/User'

import XLSX from 'xlsx'

export default {
  data() {
    return {
      subject: '',
      message: '',
      showMessageModal: false,
      fields: [
        {
          key: 'antiquity',
          label: 'Usuario desde'
        },
        {
          key: 'name',
          label: 'Nombre'
        },
        {
          key: 'institute',
          label: 'Escuela'
        },
        {
          key: 'username',
          label: 'Correo electrónico'
        },
        {
          key: 'phone',
          lable: 'Número'
        },
        {
          key: 'actions',
          label: 'Acciones'
        },
      ],
      admin_fields: [
        {
          key: 'name',
          label: 'Nombre'
        },
        {
          key: 'username',
          label: 'Correo'
        },
        {
          key: 'roleName',
          label: 'Rol'
        }
      ],
      allUsers: [],
      orderFilter: 'descending',
      filters: [
        {
          value: 'ascending',
          text: 'Ascendente'
        },
        {
          value: 'descending',
          text: 'Descendente'
        }
      ],
      copying: false,
      showTeachers: false
    }
  },
  computed: {
    admins() {
      return this.allUsers.filter(user => ['admin', 'editor'].includes(user.roleName))
    },
    teachers() {
      return this.allUsers.filter(user => user.roleName == 'teacher')
    },
    users() {
      return this.allUsers.filter(user => user.roleName == 'user')
    },
  },
  methods: {
    async loadUsers() {
      try {
        this.allUsers = await new Parse.Query(User)
          .limit(5000)
          .include('role')
          .find()
      } catch (error) {
        this.showError(`Ocurrió un error obteniendo maestros. Por favor intenta de nuevo. (${error.message})`)
      }
    },
    async sendMailToAudience() {
      try {
        let { status } = await Parse.Cloud.run('sendMailToAudience', {
          "audience": "user",
          "subject": this.subject,
          "message": this.message
        })

        if (status == 'success') {
          this.subject = ''
          this.message = ''
          this.showMessageModal = false;

          this.showSuccess(`Correo enviado con éxito`)
          return
        }

        this.showError(`Ocurrió un error enviando correo.`)
      } catch (error) {
        this.showError(`Ocurrió un error enviando correo. Por favor intenta de nuevo. (${error.message})`)
      }
    },
    async exportUserList() {
      let users = this.showTeachers ? this.teachers : this.users

      let csvData = users.map(user => ({
        "Fecha de creación": user.createdAt,
        "Nombre": user.name,
        "Correo electrónico": user.email,
        "Teléfono": user.phone,
        "Grado": user.grade || '-',
        "Escuela": user.institute || '-'
      }))

      let data = XLSX.utils.json_to_sheet(csvData)
      const workbook = XLSX.utils.book_new()
      const filename = this.showTeachers ? 'Lista maestros' : 'Lista usuarios'
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    async copyEmails() {
      var textArea = document.createElement("textarea");
      let users = this.showTeachers ? this.teachers : this.users
      textArea.value = users.map(u => u.username).join(', ');

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
        this.showSuccess(`Correos copiados al portapapeles.`)
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
        this.showError(`Ocurrió un error copiando los correos al portapapeles. (${err.message})`)
      }

      document.body.removeChild(textArea);
    }
  },
  mounted() {
    this.loadUsers()
  }
}
</script>