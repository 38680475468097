import Parse from 'parse'
import moment from 'moment-timezone'

moment.locale('es')
moment.tz.setDefault('America/Mexico_City')

export default class Card extends Parse.Object {

  subcategories = []

  constructor() {
    super('Card')
  }

  get expMonth () {
    return this.get('expMonth')
  }

  get active () {
    return this.get('active')
  }

  get user () {
    return this.get('user')
  }

  get cardHolderName () {
    return this.get('cardHolderName')
  }

  get brand () {
    return this.get('brand')
  }

  get expYear () {
    return this.get('expYear')
  }

  get lastFour () {
    return this.get('lastFour')
  }

  get createdAt() {
    return moment(this.get('createdAt')).format('dddd DD MMMM, YYYY')
  }

  static count() {
    return new Parse.Query(this).count()
  }

  static load(limit = 500, skip = 0) {
    return new Parse.Query(this)
      .limit(limit)
      .skip(skip)
      .descending('createdAt')
      .find()
  }

  static getObject(id) {
    return new Parse.Query(this).get(id)
  }

  dump() {
    console.log(this)
  }
}