<template lang="pug">
b-container.py-5
  h5.mb-3.font-weight-bold Maestros
  b-row.mb-3
    b-col(cols='6')
      b-card
        p.mb-2.font-weight-bold Código para maestros
        b-row
          b-col(cols='8')
            p.mb-1 Código vigente:
            b-form-row(v-if='code')
              b-col
                b-form-input.form-control-flush.text-center(:type='showCode ? "text" : "password"', :value='code.id || ""', disabled)
              b-col(cols='auto')
                b-button.text-reset(variant='link', @click='showCode = !showCode', size='sm')
                  eye-icon.icon-sm.mr-2
                  span Mostrar
            b-form-row(v-else)
              b-col
                .my-3.text-secondary No se ha generado un código
        p.mb-1 Vigencia:
        p.mb-0.font-weight-bold {{ code ? $moment(code.expiration).format("DD MMMM YYYY") : "-" }}
        .text-right
          b-button(variant='gradient-primary', @click='shareCodeModal = true', :disabled='!code') Compartir código
    b-col(cols='6')
      b-card
        p.mb-2.font-weight-bold Generar nuevo código
        b-form-row.mb-2
          b-col(cols='8')
            span Selecciona una fecha de vencimiento:
          b-col(cols='4')
            datepicker(v-model='expirationDate', input-class='form-control', :bootstrap-styling='true', :language='es', :disabled-dates='{ to }')
        .text-right
          b-button(variant='gradient-primary', @click='confirmUpdateModal = true') Generar código
  b-row
    b-col
    b-col(cols='auto')
      b-button.text-reset(variant='link', @click='exportList') Exportar lista de maestros
  b-card 
    b-row.mb-3
      b-col(cols='7')
        p.mb-2.font-weight-bold Maestros registrados
        b-button.text-reset.text-underline(variant='link', @click='copyEmails') Copiar correos electrónicos
          b-spinner.ml-2(small, variant='primary', v-if='copying')
      b-col(cols='5')
        b-form-row.align-items-center
          b-col(cols='auto')
            span Filtrar por:
          b-col
            b-form-select(:options='filters', v-model='orderFilter', @change='loadTeachers')
    b-table.card-table.mb-0(hover, :fields='fields', :items='teachers', responsive, show-empty)
      template(#empty)
        p.text-center.mb-0.text-secondary No hay datos para mostrar
      template(#cell(antiquity)='{item}')
        span.font-weight-bold {{ $moment(item.antiquity).format("DD/MM/YY") }}
      template(#cell(email)='{item}')
        b-link.font-weight-bold(:href='`mailto:${item.email}`') {{ item.email }}
      template(#cell(actions)='{item}')
        b-button.text-reset.font-weight-bold.text-underline(variant='link', @click='disableUser(item.id)') Eliminar

  full-modal(:visible='shareCodeModal', @cancel='shareCodeModal = false')
    b-container
      validation-observer(v-slot='{invalid, handleSubmit}')
        b-form(@submit.prevent='handleSubmit(shareCodeEmail)')
          b-form-row.mb-5.justify-content-center
            b-col.text-center.font-weight-bold(cols='5')
              p.mb-0 Introduce el correo electrónico de la persona con quien se compartirá el código:
            b-col(cols='5')
              validation-provider(name='correo electrónico', rules='required|email', v-slot='{ errors }')
                b-form-input.w-100.text-center(placeholder='Introduce el correo electrónico aquí', type='email', v-model='shareEmail', :state='errors.length ? false : null')
          .text-center 
            b-button(variant='gradient-primary', :disabled='invalid', type='submit') Compartir

  full-modal(:visible='confirmUpdateModal', @cancel='confirmUpdateModal = false')
    b-container
      b-row
        b-col.text-center
          p.font-weight-bold ¿Estás seguro que deseas dar de alta un nuevo código?
          p.font-weight-bold En caso de que haya un código vigente, éste será dado de baja y ya no será válido.
          p
            b-button.font-weight-bold(variant='light', @click='confirmUpdateModal = false', pill, :disabled='loading') Cancelar
          b-button(variant='gradient-primary', @click='generateCode', :disabled='loading') Continuar

  full-modal(:visible='successUpdateModal', :cancelable='false')
    b-container
      b-row
        b-col.font-weight-bold.text-center
          p El código para maestros ha sido actualizado.
          b-button(variant='gradient-primary', @click='successUpdateModal = false; getActiveCode()') Continuar

  full-modal(:visible='successShareModal', :cancelable='false')
    b-container
      b-row
        b-col.font-weight-bold.text-center
          p El código para maestros ha sido compartido correctamente.
          b-button(variant='gradient-primary', @click='successShareModal = false') Continuar

  full-modal(:visible='showMessageModal', @cancel='showMessageModal = false')
    b-container
      validation-observer(v-slot='{invalid, handleSubmit}')
        b-form(@submit.prevent='handleSubmit(sendMailToAudience)')
          b-form-row.mb-5.justify-content-center
            b-col.text-center.font-weight-bold(cols='5')
              p.mb-0 Asunto del mensaje:
            b-col(cols='5')
              validation-provider(name='asunto', rules='required|min:1', v-slot='{ errors }')
                b-form-input.w-100.text-center.mb-2(placeholder='Asunto del mensaje', v-model='subject', :state='errors.length ? false : null')
          b-form-row.mb-5.justify-content-center
            b-col.text-center.font-weight-bold(cols='5')
              p.mb-0 Mensaje:
            b-col(cols='5')
              validation-provider(name='mensaje', rules='required|min:1', v-slot='{ errors }')
                b-form-input.w-100.text-center.mb-2(placeholder='Mensaje', v-model='message', :state='errors.length ? false : null')
          .text-center 
            b-button(variant='gradient-primary', :disabled='invalid', type='submit') Enviar
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/dist/locale'

import Parse from 'parse'
import Batch from '@/parse/models/Batch'

import XLSX from 'xlsx'

export default {
  data() {
    return {
      es: es,
      to: new Date(),
      expirationDate: new Date(),
      shareCodeModal: false,
      successUpdateModal: false,
      successShareModal: false,
      confirmUpdateModal: false,
      showCode: false,
      fields: [
        {
          key: 'antiquity',
          label: 'Usuario desde'
        },
        {
          key: 'name',
          label: 'Nombre'
        },
        {
          key: 'grade',
          label: 'Nivel escolar'
        },
        {
          key: 'email',
          label: 'Correo electrónico'
        },
        {
          key: 'phone',
          lable: 'Número'
        },
        {
          key: 'actions',
          label: 'Acciones'
        },
      ],
      teachers: [],
      orderFilter: null,
      filters: [
        {
          value: null,
          text: 'Todos'
        },
        {
          value: 'Primaria',
          text: 'Primaria'
        },
        {
          value: 'Secundaria',
          text: 'Secundaria'
        },
        {
          value: 'Bachillerato',
          text: 'Bachillerato'
        },
      ],
      shareEmail: '',
      loading: false,
      code: null,
      showMessageModal: false,
      subject: '',
      message: '',
      copying: false
    }
  },
  methods: {
    generateMonths() {
      this.months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'].map((e, index) => { return { value: index, text: e } });
    },
    async loadTeachers() {
      try {
        this.teachers = await Parse.Cloud.run('usersDetailList', { "type": 'teacher', grade: this.orderFilter })
      } catch (error) {
        this.showError(`Ocurrió un error obteniendo maestros. Por favor intenta de nuevo. (${error.message})`)
      }
    },
    async shareCodeEmail() {
      try {
        await Parse.Cloud.run('shareCode', { "email": this.shareEmail })
        this.shareCodeModal = false;
        this.successShareModal = true;
      } catch (error) {
        this.showError(`Ocurrió un error compartiendo código. Por favor intenta de nuevo. (${error.message})`)
      }
    },
    async generateCode() {
      this.loading = true

      let params = {
        event: "generate",
        type: "teacher",
        expiration: this.$moment(this.expirationDate).toDate()
      }

      console.log("🚀 ~ file: Maestros.vue ~ line 221 ~ generateCode ~ params", params)

      try {
        await Parse.Cloud.run('manageKey', params)

        this.confirmUpdateModal = false;
        this.successUpdateModal = true;
      } catch (error) {
        this.showError(`Ocurrió un error generando código. Por favor intenta de nuevo. (${e.message})`)
      }

      this.loading = false;

    },
    async getActiveCode() {
      this.code = await Batch.getActiveCode()
    },
    sendMailToAudience() {
      Parse.Cloud.run('sendMailToAudience', {
        "audience": "teacher",
        "subject": this.subject,
        "message": this.message
      }).then(res => {
        if (res.status == 'success') {
          this.subject = ''
          this.message = ''
          this.showMessageModal = false;

          this.showSuccess(`Correo enviado con éxito`)
        } else {
          this.showError(`Ocurrió un error enviando correo.`)
        }
      }).catch(e => {
        this.showError(`Ocurrió un error enviando correo. Por favor intenta de nuevo. (${e.message})`)
      })
    },
    async disableUser(id, enabled = false) {
      try {
        await Parse.Cloud.run('enabledUser', { user: id, enabled })
        this.showSuccess('Usuario ha sido deshabilitado')
      } catch (error) {
        this.showError(`Ocurrió un error deshabilitando usuario. Por favor intenta de nuevo. (${e.message})`)
      }
    },
    async copyEmails() {
      this.copying = true;
      let users = await Parse.Cloud.run('usersDetailList', { "type": 'teacher' });

      this.copying = false;

      var textArea = document.createElement("textarea");
      textArea.value = users.map(u => u.email).join(', ');

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);

        this.showSuccess(`Correos copiados al portapapeles.`)
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
        this.showError(`Ocurrió un error copiando los correos al portapapeles. (${err.message})`)
      }

      document.body.removeChild(textArea);
    },
    async exportList() {
      let csvData = this.teachers.map(user => ({
        "Fecha de creación": user.antiquity,
        "Nombre": user.name,
        "Correo electrónico": user.email,
        "Teléfono": user.phone,
        "Grado": user.grade || '-',
      }))

      let data = XLSX.utils.json_to_sheet(csvData)
      const workbook = XLSX.utils.book_new()
      const filename = 'Lista maestros'
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
  },
  mounted() {
    this.generateMonths();
    this.loadTeachers()
    this.getActiveCode()
  },
  components: {
    Datepicker
  }
}
</script>

<style lang="scss" scoped>
</style>