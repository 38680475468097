import Parse from 'parse'
import moment from 'moment-timezone'

moment.locale('es')
moment.tz.setDefault('America/Mexico_City')

class User extends Parse.User {

  constructor() {
    super('_User')
  }

  get createdAt() {
    return moment(this.get('createdAt')).format('DD/MM/YYYY')
  }

  get username() {
    return this.get('username')
  }

  get name() {
    return this.get('name')
  }

  get email () {
    return this.get('email')
  }

  get institute () {
    return this.get('institute')
  }

  get grade () {
    return this.get('grade')
  }

  get role () {
    return this.get('role')
  }

  get roleName () {
    return this.role.get('name')
  }

  get address () {
    return this.get('address') || {}
  }

  get phone () {
    return this.get('phone')
  }

  async loadBooks() {
    return this.relation('books')
      .query()
      .find()
  }

  async loadCards() {
    return this.relation('cards')
      .query()
      .find()
  }

  async loadBuy() {
    return this.relation('buy')
      .query()
      .find()
  }

  async loadSales() {
    return this.relation('sales')
      .query()
      .find()
  }

  static search(content, limit = 100, skip = 0) {
    let Role = Parse.Object.extend("_Role")
    let userRole = Role.createWithoutData("t6br3c91ec")

    let queryName = new Parse.Query(this).matches('name', content, 'i')
    let queryLastname = new Parse.Query(this).matches('lastname', content, 'i')
    let queryUsername = new Parse.Query(this).contains('username', content)
    let queryPhone = new Parse.Query(this).contains('phone', content)

    return Parse.Query.or(queryName, queryLastname, queryUsername, queryPhone)
      .limit(limit)
      .skip(skip)
      .descending('createdAt')
      .equalTo('role', userRole)
      .find()
  }

  static count() {
    return new Parse.Query(this).count()
  }

  static load(limit = 500, skip = 0, queryData = {}) {
    let Role = Parse.Object.extend("_Role")
    let userRole = Role.createWithoutData("t6br3c91ec")

    if (queryData.name != undefined) {
      let query1 = new Parse.Query(this)
        .matches('name', queryData.name, 'i')

      let query3 = new Parse.Query(this)
        .matches('lastName', queryData.name, 'i')

      let query2 = new Parse.Query(this)
        .matches('email', queryData.name, 'i')

      let query = new Parse.Query.or(query1, query2, query3)
        .limit(limit)
        .skip(skip)
        .equalTo('role', userRole)
        .withCount()

        query = query[queryData.orderFilter || 'descending']('createdAt')
        
      return query.find()

    } else {
      let query = new Parse.Query(this)
      .limit(limit)
      .skip(skip)
      .equalTo('role', userRole)
      .withCount()

      query = query[queryData.orderFilter || 'descending']('createdAt')

      return query.find()
    }
  }

  static loadTeachers(limit = 500, skip = 0, queryData = {}) {
    let Role = Parse.Object.extend("_Role")
    let userRole = Role.createWithoutData("t6br3c91ec")

    if (queryData.name != undefined) {
      let query1 = new Parse.Query(this)
        .matches('name', queryData.name, 'i')

      let query3 = new Parse.Query(this)
        .matches('lastName', queryData.name, 'i')

      let query2 = new Parse.Query(this)
        .matches('email', queryData.name, 'i')

      let query = new Parse.Query.or(query1, query2, query3)
        .limit(limit)
        .skip(skip)
        .equalTo('role', userRole)
        .withCount()

        query = query[queryData.orderFilter || 'descending']('createdAt')
        
      return query.find()

    } else {
      let query = new Parse.Query(this)
      .limit(limit)
      .skip(skip)
      .equalTo('role', userRole)
      .withCount()

      query = query[queryData.orderFilter || 'descending']('createdAt')

      return query.find()
    }
  }

  static emails(type) {
    let teacherRoleId = 't6br3c91ec';
    let userRoleId = '5aPSTxzHkx'
    
    let Role = Parse.Object.extend("_Role")
    let userRole = Role.createWithoutData(type == 'user' ? userRoleId : teacherRoleId)

    let query = new Parse.Query(this)
      .limit(1000)
      .skip(0)
      .equalTo('role', userRole)
      

      return query.find()
  }

  static getObject(id) {
    return new Parse.Query(this).get(id)
  }

  dump() {
    console.log(this)
  }
}

var getLastNameInitial = function (lastname) {
  if (!lastname) return ''
  return lastname.slice(0, 1).toUpperCase()
}

export default User
