<template lang="pug">
b-container.py-5
  b-row.mb-3
    b-col
      h5.font-weight-bold Ventas
    b-col(cols='auto')
      b-button.text-reset(variant='link', :to='{ name: "Ventas" }')
        arrow-left-circle-icon
        span.ml-2 Regresar

  b-card 
    p.font-weight-bold Historial de ventas
    b-table.card-table.mb-0(hover, :fields='fields', :items='orders', responsive, show-empty)
      template(#empty)
        p.text-center.mb-0.text-secondary No hay datos para mostrar
      template(#cell(user)='{item}')
        b-link.font-weight-bold(:href='`mailto:${item.user}`') {{ item.user }}
      template(#cell(createdAt)='{item}')
        span.font-weight-bold {{ item.createdAt }}
      template(#cell(total)='{item}')
        span ${{ formatMoney(item.total) || 0 }}
  b-pagination(v-model='paginationPage', :total-rows='paginationCount', :per-page='paginationLimit', @change='loadOrders', v-if='!paginationLoading')
</template>

<script>
import Order from '@/parse/models/Order'

export default {
  data() {
    return {
      fields: [
        {
          key: 'createdAt',
          label: 'Fecha'
        },
        {
          key: 'book.title',
          label: 'Libro'
        },
        {
          key: 'user.name',
          label: 'Usuario'
        },
        {
          key: 'address',
          label: 'Dirección'
        },
        {
          key: 'packageString',
          label: 'Formato'
        },
        {
          key: 'status',
          label: 'Estatus'
        },
        {
          key: 'trackingGuide',
          label: 'No. Guía'
        },
        'total'
      ],
      orders: [],
    }
  },
  methods: {
    async loadOrders() {
      let orders = await Order.load(this.paginationLimit, this.paginationLimit * (this.paginationPage - 1), true)
      this.orders = orders.results
      this.totalCount = orders.count
    },
  },
  mounted() {
    this.loadOrders()
  }
}
</script>

<style>
</style>