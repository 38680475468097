import Parse from 'parse'
import moment from 'moment-timezone'

moment.locale('es')
moment.tz.setDefault('America/Mexico_City')

export default class Category extends Parse.Object {

  subcategories = []

  constructor() {
    super('Category')
  }

  get enabled () {
    return this.get('enabled')
  }

  get name () {
    return this.get('name')
  }

  get order () {
    return this.get('order')
  }

  get createdAt() {
    return moment(this.get('createdAt')).format('dddd DD MMMM, YYYY')
  }

  async loadBooks() {
    return this.relation('books')
      .query()
      .find()
  }

  static count() {
    return new Parse.Query(this).count()
  }

  static load(limit = 500, skip = 0) {
    return new Parse.Query(this)
      .limit(limit)
      .skip(skip)
      .equalTo('enabled', true)
      .descending('createdAt')
      .find()
  }

  static getObject(id) {
    return new Parse.Query(this).get(id)
  }

  dump() {
    console.log(this)
  }
}