<template lang="pug">
div
  .side-bar-menu-container(style='background: #e9e9e9')
    logo.mb-2
    .mt-3
      b-nav(vertical)
        b-nav-item(link-classes='menu-link', exact-active-class='menu-link-active', :to='{ name: "Ventas" }') Ventas
          .active-sign.align-items-center.justify-content-center(style='display: none')
            .circle.d-flex.align-items-center.justify-content-center
              chevron-left-icon(style='color: white')
      b-nav(v-if='isAuth' vertical)
        b-nav-item(link-classes='menu-link', exact-active-class='menu-link-active', :to='{ name: "Escuelas" }') Escuelas
          .active-sign.align-items-center.justify-content-center(style='display: none')
            .circle.d-flex.align-items-center.justify-content-center
              chevron-left-icon(style='color: white')
        b-nav-item(link-classes='menu-link', exact-active-class='menu-link-active', :to='{ name: "Usuarios" }') Usuarios
          .active-sign.align-items-center.justify-content-center(style='display: none')
            .circle.d-flex.align-items-center.justify-content-center
              chevron-left-icon(style='color: white')
        b-nav-item(link-classes='menu-link', exact-active-class='menu-link-active', :to='{ name: "Maestros" }') Maestros
          .active-sign.align-items-center.justify-content-center(style='display: none')
            .circle.d-flex.align-items-center.justify-content-center
              chevron-left-icon(style='color: white')
        b-nav-item(link-classes='menu-link', exact-active-class='menu-link-active', :to='{ name: "Contenido" }') Contenido
          .active-sign.align-items-center.justify-content-center(style='display: none')
            .circle.d-flex.align-items-center.justify-content-center
              chevron-left-icon(style='color: white')
  .dashboard-view-container
    b-navbar.justify-content-end(variant='transparent', type='light')
      b-navbar-nav
        b-nav-item.font-weight-bold.text-sm(link-classes='nav-link-dark', @click='doLogout') 
          b-spinner.mr-2(small, variant='dark', v-if='loading') 
          span Cerrar sesión
    router-view
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Parse from 'parse'

export default {
  data() {
    return {
      loading: false,
    }
  },
  components: {
    'logo': () => import('@/components/Logo.vue')
  },
  computed: {
    isAuth() {
      // el fetchedUser es un pequeño hack que describiré 
      // paso a paso en mi libro reactividad for dummies 👌
      return Boolean(Parse.User.current() && Parse.User.current().role.get('name') == 'admin') && this.fetchedUser == false ? true : true
    },
    ...mapGetters(['isAdmin', 'isEditor'])
  },
  methods: {
    ...mapActions(['logout']),
    async doLogout() {
      this.loading = true;
      try {
        this.logout()
        this.$router.push({ name: 'Login' })
      } catch (error) {
        this.showError(error.message)
      }
      this.loading = false;
    },
  },
  mounted() {
    if (!Parse.User.current().role.get('name')) {
      Parse.User.current().role.fetch().then(updated => {
        this.fetchedUser = true;
      }).catch(e => console.log(e));
    }
  }
}
</script>

<style lang="scss" scoped>
$side-bar-width: 200px;

.side-bar-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: $side-bar-width;
  padding: 2rem;
}

.dashboard-view-container {
  width: 100%;
  min-height: 10vh;
  padding-left: calc(200px + 30px);
}

.nav-link-dark {
  color: #000 !important;
}

.menu-link {
  padding: 2rem 0;
  color: black !important;
}

.menu-link-active {
  position: relative;
  font-weight: bold;
  // float: left;

  .active-sign {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(200px - 2rem - 15px);
    display: flex !important;
    width: 60px;
    height: 60px;
    border-radius: 20px;
    transform: rotate(45deg);
    background-color: white;

    .circle {
      transform: rotate(-45deg);
      display: inline-block;
      position: relative;
      right: 7px;
      top: 7px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-image: -webkit-gradient(linear, left top, right top, #746191, #eb7171);
      background-image: -o-linear-gradient(left, #746191, #eb7171);
      background-image: linear-gradient(to right, #746191, #eb7171);
    }
  }
}
</style>