var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"py-5"},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Administradores")])])],1),_c('b-card',[_c('b-table',{staticClass:"card-table mb-0",attrs:{"hover":"","fields":_vm.admin_fields,"items":_vm.admins,"responsive":"","show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"text-center mb-0 text-secondary"},[_vm._v("No hay datos para mostrar")])]},proxy:true}])})],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.showTeachers ? "Maestros" : "Usuarios"))]),_c('b-button',{attrs:{"variant":"gradient-primary"},on:{"click":function($event){_vm.showTeachers = !_vm.showTeachers}}},[_vm._v("Mostrar "+_vm._s(_vm.showTeachers ? "usuarios" : "maestros"))])],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"text-reset",attrs:{"variant":"link"},on:{"click":_vm.exportUserList}},[_vm._v("Exportar lista de "+_vm._s(_vm.showTeachers ? "maestros" : "usuarios"))])],1)],1),_c('b-card',[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"7"}},[_c('p',{staticClass:"mb-2 font-weight-bold"},[_vm._v(_vm._s(_vm.showTeachers ? "Maestros" : "Usuarios")+" registrados")]),_c('b-button',{staticClass:"text-reset text-underline",attrs:{"variant":"link"},on:{"click":_vm.copyEmails}},[_vm._v("Copiar correos electrónicos"),(_vm.copying)?_c('b-spinner',{staticClass:"ml-2",attrs:{"small":"","variant":"primary"}}):_vm._e()],1)],1)],1),_c('b-table',{staticClass:"card-table mb-0",attrs:{"hover":"","fields":_vm.fields,"items":_vm.showTeachers ? _vm.teachers : _vm.users,"responsive":"","show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"text-center mb-0 text-secondary"},[_vm._v("No hay datos para mostrar")])]},proxy:true},{key:"cell(antiquity)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$moment(item.antiquity).format("DD/MM/YY")))])]}},{key:"cell(total)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.total))])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.status))])]}},{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{staticClass:"font-weight-bold",attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"text-reset font-weight-bold text-underline",attrs:{"variant":"link"},on:{"click":function($event){return _vm.$router.push({ name: "Usuarios/Usuario", params: { usuario_id: item.id } })}}},[_vm._v("Ver detalles")])]}}])})],1),(!_vm.paginationLoading)?_c('b-pagination',{attrs:{"total-rows":_vm.paginationCount,"per-page":_vm.paginationLimit},on:{"change":_vm.loadUsers},model:{value:(_vm.paginationPage),callback:function ($$v) {_vm.paginationPage=$$v},expression:"paginationPage"}}):_vm._e(),_c('full-modal',{attrs:{"visible":_vm.showMessageModal},on:{"cancel":function($event){_vm.showMessageModal = false}}},[_c('b-container',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendMailToAudience)}}},[_c('b-form-row',{staticClass:"mb-5 justify-content-center"},[_c('b-col',{staticClass:"text-center font-weight-bold",attrs:{"cols":"5"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Asunto del mensaje:")])]),_c('b-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"asunto","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-100 text-center mb-2",attrs:{"placeholder":"Asunto del mensaje","state":errors.length ? false : null},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})]}}],null,true)})],1)],1),_c('b-form-row',{staticClass:"mb-5 justify-content-center"},[_c('b-col',{staticClass:"text-center font-weight-bold",attrs:{"cols":"5"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Mensaje:")])]),_c('b-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"mensaje","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-100 text-center mb-2",attrs:{"placeholder":"Mensaje","state":errors.length ? false : null},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"variant":"gradient-primary","disabled":invalid,"type":"submit"}},[_vm._v("Enviar")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }