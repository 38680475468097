import Parse from 'parse'
import moment from 'moment-timezone'

moment.locale('es')
moment.tz.setDefault('America/Mexico_City')

import Institute from './Institute'

export default class Batch extends Parse.Object {

  subcategories = []

  constructor() {
    super('Batch')
  }

  get enabled () {
    return this.get('enabled')
  }

  set enabled (value) {
    this.set('enabled', value)
  }

  get subtotal () {
    return this.get('subTotal')
  }

  get isTeacherKey () {
    return this.get('isTeacherKey')
  }

  get book () {
    return this.get('book')
  }

  get expiration() {
    return this.get('expiration')
  }

  get institute () {
    return this.get('institute')
  }

  get emails () {
    return this.get('emails')
  }

  get createdAt() {
    return moment(this.get('createdAt')).format('dddd DD MMMM, YYYY')
  }

  static count() {
    return new Parse.Query(this).count()
  }

  static load(limit = 500, skip = 0) {
    let query = new Parse.Query(this)
      .limit(limit)
      .skip(skip)
      .descending('createdAt')
      .withCount()

    return query.find()
  }

  static loadWhereInstitute(limit = 500, skip = 0, id) {
    let institute = Institute.createWithoutData(id)

    let query = new Parse.Query(this)
      .limit(limit)
      .skip(skip)
      .descending('createdAt')
      .equalTo('institute', institute)
      .withCount()

    return query.find()
  }

  static getActiveCode() {
    return new Parse.Query(this)
      .descending('createdAt')
      .equalTo('isTeacherKey', true)
      .equalTo('enabled', true)
      .first()
  }

  static getObject(id) {
    return new Parse.Query(this).get(id)
  }

  dump() {
    console.log(this)
  }
}