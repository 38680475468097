<template lang="pug">
b-container.py-5
  b-row.mb-3
    b-col
      h5.font-weight-bold {{ Institute.name }}
    b-col(cols='auto')
      b-button.text-reset(variant='link', :to='{ name: "Escuelas" }')
        arrow-left-circle-icon
        span.ml-2 Regresar

  b-row.mb-3
    b-col(cols='12')
      b-card
        p.mb-2.font-weight-bold Detalle de escuela
        b-row
          b-col.mb-3(cols='12')
            b-form-row
              b-col(cols='auto')
                p.mb-0 Nombre:
              b-col(v-if='!edit')
                p.mb-0.font-weight-bold {{ Institute.name }}
              b-col(v-else)
                b-form-input(placeholder='Nombre', v-model='Institute.name')

        .text-right.mt-5
          b-button.px-5(variant='light', @click='edit = true') Editar
          b-button.ml-3.px-5(variant='light', @click='saveInstitute') Guardar cambios

  b-card 
    b-row
      b-col
        p.font-weight-bold Ventas grupales
      b-col(cols='auto')
        //- b-button(variant="light", @click="showGroup = !showGroup") 
        //-   span.mr-5 {{ showGroup ? 'Ventas individuales' : 'Ventas grupales' }}
        //-   chevron-right-icon.icon-sm
    b-table.card-table(hover, :fields='Fields', :items='Items', responsive, show-empty)
      template(#empty)
        span.text-secondary No hay datos para mostrar
      template(#cell(id)='{item}')
        span.font-weight-bold.text-underline {{ item.id }}
      template(#cell(date)='{item}')
        span.font-weight-bold {{ $moment(item.date).format("DD/MM/YY") }}
      template(#cell(booksIncluded)='{item}')
        b-button.text-reset.font-weight-bold(variant='link') Ver lista
      template(#cell(actions)='{item}')
        b-button.text-reset.font-weight-bold(variant='link', @click='deleteBatchId = item.saleId') Eliminar
  b-pagination(v-model='paginationPage', :total-rows='paginationCount', :per-page='paginationLimit', @change='loadData', v-if='!paginationLoading')

  full-modal(:visible='deleteBatchId != null', @cancel='deleteBatchId = null')
    b-container
      h4.text-center ¿Estás seguro de eliminar la venta grupal?, los usuarios que hayan usado el código ya no tendrán acceso al contenido relacionado
      //- validation-observer(v-slot='{invalid, handleSubmit}')
      //-   b-form(@submit.prevent='handleSubmit(sendMailToAudience)')
      //-     b-form-row.mb-5.justify-content-center
      //-       b-col.text-center.font-weight-bold(cols='5')
      //-         p.mb-0 Asunto del mensaje:
      //-       b-col(cols='5')
      //-         validation-provider(name='asunto', rules='required|min:1', v-slot='{ errors }')
      //-           b-form-input.w-100.text-center.mb-2(placeholder='Asunto del mensaje', v-model='subject', :state='errors.length ? false : null')
      //-     b-form-row.mb-5.justify-content-center
      //-       b-col.text-center.font-weight-bold(cols='5')
      //-         p.mb-0 Mensaje:
      //-       b-col(cols='5')
      //-         validation-provider(name='mensaje', rules='required|min:1', v-slot='{ errors }')
      //-           b-form-input.w-100.text-center.mb-2(placeholder='Mensaje', v-model='message', :state='errors.length ? false : null')
      .text-center.mt-5
        b-button(variant='gradient-primary', :disabled='loading', @click='deleteBatch') Eliminar
</template>

<script>
import Parse from 'parse'
import Batch from '@/parse/models/Batch'
import Institute from '@/parse/models/Institute'

export default {
  data() {
    return {
      Institute: {},
      edit: false,
      showGroup: true,
      fields: [
        {
          key: 'date',
          label: 'Fecha'
        },
        {
          key: 'saleId',
          label: 'ID de Venta'
        },
        'subtotal',
        'total',
        {
          key: 'book',
          label: 'Libros incluídos'
        },
        {
          key: 'numStudents',
          label: 'Total de alumnos'
        },
        {
          key: 'actions',
          label: 'Acciones'
        },
      ],
      batches: [],
      fieldsIndividual: [
        {
          key: 'createdAt',
          label: 'Fecha'
        },
        {
          key: 'user',
          label: 'Usuario'
        },
        {
          key: 'product',
          label: 'Producto'
        },
        {
          key: 'format',
          label: 'Formato'
        },
        'subtotal',
        {
          key: 'delivery',
          label: 'Envío'
        },
        {
          key: 'comission',
          label: 'Comisión'
        },
        'total'
      ],
      individualSales: [],
      deleteBatchId: null,
      loading: false
    }
  },
  computed: {
    Items() {
      if (this.showGroup) {
        return this.batches
      } else {
        return this.individualSales
      }
    },
    Fields() {
      if (this.showGroup) {
        return this.fields
      } else {
        return this.fields
      }
    },
  },
  methods: {
    async deleteBatch() {
      if (!this.deleteBatchId) return
      this.loading = true
      try {
        let batch = Batch.createWithoutData(this.deleteBatchId)
        await batch.destroy()
        this.showSuccess(`La venta grupal fue eliminada`)
        this.loadData()
        this.deleteBatchId = null
      } catch (error) {
        this.showError(`No fue posible eliminar la venta grupal: ${error.message}`)
      }
      this.loading = false
    },
    async loadBatches() {
      Parse.Cloud.run('reportPurchase', {
        institute: this.$route.params.escuela_id,
        type: 'group'
      }).then(res => {
        console.log(res);
        this.batches = res
      }).catch(e => {
        this.$bvToast.toast(`Ocurrió un error cargando ventas grupales. Por favor intenta de nuevo. (${e.message})`, {
          title: 'Ocurrió un error',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          variant: 'danger'
        })
      })
    },
    async loadIndividualSales() {
      Parse.Cloud.run('reportPurchase', {
        institute: this.$route.params.escuela_id,
        type: 'individual'
      }).then(res => {
        console.log(res);
        this.individualSales = res
      }).catch(e => {
        this.$bvToast.toast(`Ocurrió un error cargando ventas individuales. Por favor intenta de nuevo. (${e.message})`, {
          title: 'Ocurrió un error',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          variant: 'danger'
        })
      })
    },
    loadData() {
      if (this.showGroup) {
        this.loadBatches()
      } else {
        this.loadIndividualSales()
      }
    },
    saveInstitute() {
      this.Institute.save().then(res => {
        // console.log(res.name);
        this.edit = false;
        this.$bvToast.toast(`Instituto actualizado`, {
          title: 'Actualizado',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          variant: 'success'
        })
      }).catch(e => {
        this.$bvToast.toast(`Ocurrió un error actualizando. Por favor intenta de nuevo. (${e.message})`, {
          title: 'Ocurrió un error',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          variant: 'danger'
        })
      })
    }
  },
  async mounted() {
    this.loadData()
    this.Institute = await Institute.getObject(this.$route.params.escuela_id)
  },
  watch: {
    showGroup(value) {
      console.log(value);
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>