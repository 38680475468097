import Vue from 'vue'
import VueRouter from 'vue-router'

import Parse from 'parse'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: to => {
      if (Parse.User.current()) {
        return {name: 'Dashboard'}
      } else {
        return {name: 'Login'}
      }
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue'),
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import('@/views/login/Email.vue')
      },
      {
        path: 'password',
        name: 'Login/Password',
        component: () => import('@/views/login/Password.vue')
      }
    ]
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    redirect: {name: 'Ventas'},
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'ventas',
        component: () => import('@/components/Router.vue'),
        children: [
          {
            path: '',
            name: 'Ventas',
            component: () => import('@/views/sections/Ventas.vue')
          },
          {
            path: 'historial',
            name: 'Ventas/History',
            component: () => import('@/views/sections/HistorialVentas.vue')
          }
        ]
      },
      {
        path: 'maestros',
        name: 'Maestros',
        component: () => import('@/views/sections/Maestros.vue'),
        meta: { requiredRole: 'admin' }
      },
      {
        path: 'usuarios',
        component: () => import('@/components/Router.vue'),
        meta: { requiredRole: 'admin' },
        children: [
          {
            path: '',
            name: 'Usuarios',
            component: () => import('@/views/sections/Usuarios.vue')
          },
          {
            path: ':usuario_id',
            name: 'Usuarios/Usuario',
            component: () => import('@/views/sections/Usuario.vue')
          }
        ]
      },
      {
        path: 'escuelas',
        component: () => import('@/components/Router.vue'),
        meta: { requiredRole: 'admin' },
        children: [
          {
            path: '',
            component: () => import('@/components/Router.vue'),
            children: [
              {
                path: '',
                name: 'Escuelas',
                component: () => import('@/views/sections/Escuelas.vue')
              },
              {
                path: ':escuela_id',
                component: () => import('@/components/Router.vue'),
                children: [
                  {
                    path: '',
                    name: 'Escuela',
                    component: () => import('@/views/sections/Escuela.vue')
                  },
                  {
                    path: 'venta-grupal',
                    component: () => import('@/components/Router.vue'),
                    children: [
                      {
                        path: '',
                        name: 'Escuelas/VentaGrupal',
                        component: () => import('@/views/sections/VentaGrupal.vue')
                      },
                      {
                        path: ':ventagrupal_id/resumen',
                        component: () => import('@/components/Router.vue'),
                        children: [
                          {
                            path: '',
                            name: 'VentaGrupal/Resumen',
                            component: () => import('@/views/sections/ResumenVentaGrupal.vue')
                          },
                          // {
                          //   path: ':ventagrupal_id',
                          //   name: 'VentaGrupal/Resumen',
                          //   component: () => import('@/views/sections/ResumenVentaGrupal.vue')
                          // }
                        ]
                      }
                    ]
                  },
                ]
              }
            ]
          },
        ]
      },
      {
        path: 'contenido',
        component: () => import('@/components/Router.vue'),
        meta: { requiredRole: 'admin' },
        children: [
          {
            path: '',
            name: 'Contenido',
            component: () => import('@/views/sections/Contenido.vue')
          },
          {
            path: ':libro_id',
            name: 'Libro',
            component: () => import('@/views/sections/Libro.vue')
          },
        ]
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  if (to.matched.some(r => r.meta.requiresAuth) && !Parse.User.current())
    return next({ name: 'Home' })

  if ( to.matched.some(r => r.meta.requiredRole && r.meta.requiredRole != Parse.User.current().role.get('name')) )
    return next({ name: 'Dashboard' })
  
  next()
})


export default router
