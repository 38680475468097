<template lang="pug">
b-container.py-5
  b-row.mb-3
    b-col
      h5.font-weight-bold {{ Institute.name }}
    b-col(cols='auto')
      b-button.text-reset(variant='link', @click='showResume ? (showResume = false) : $router.go(-1)')
        arrow-left-circle-icon
        span.ml-2 Regresar

  b-card(v-if='!showResume')
    p.font-weight-bold Nueva venta grupal
    validation-observer(v-slot='{invalid, handleSubmit}')
      b-form(@submit.prevent='handleSubmit(shouldShowResume)')
        b-row
          b-col(cols='12')
            input(style='display: none', type='file', @change='fileSelected', accept='.csv', ref='inputFile', required)
            p Lista de correos electrónicos de los alumnos:
              b.mx-2.text-underline(v-if='fileName') {{ fileName }}
              b-button.text-underline.ml-3(variant='white', @click='$refs.inputFile.click()') Seleccionar archivo (CSV)

          b-col(cols='12')
            b-row
              b-col.mb-3(cols='6')
                b-row.align-items-center
                  b-col(cols='auto')
                    p.mb-0 Seleccionar libros:
                  b-col
                    validation-provider(name='nivel escolar', rules='required', v-slot='{ errors }')
                      b-form-select(:options='categories', v-model='selectedCategory')
                        template(#first)
                          b-form-select-option(:value='null', disabled) Selecciona un nivel escolar
                      p.mb-0.text-sm.text-danger {{ errors[0] }}

              //- b-col.mb-3(cols="6")
              //-   b-form-row.align-items-center.mb-3
              //-     b-col(cols="4")
              //-       span Comisión por venta:
              //-     b-col
              //-       b-form-select(:options="comissions", v-model="comission")

              b-col.mb-3(cols='12')
                p Selecciona el libro a la venta:
                p.text-secondary(v-if='selectedCategory && !books.length') No hay libros en el nivel escolar seleccionado
                p.text-secondary(v-else-if='!selectedCategory') Selecciona un nivel escolar
                b-form-radio-group(:options='books', v-model='selectedBook', stacked)

              //- b-col.mb-3(cols="6")
              //-   b-form-row.mb-2
              //-     b-col(cols="7")
              //-       span Selecciona una fecha de vencimiento:
              //-     b-col(cols="5")
              //-       datepicker(v-model="expirationDate", input-class="form-control", :bootstrap-styling="true", :language="es", :disabled-dates="{to}")

        .text-right
          b-button.mb-2(variant='gradient-primary', type='submit', :disabled='invalid || !selectedCategory || !fileName || !selectedBook') Dar de alta venta grupal
          p.mb-0.text-danger.text-sm(v-if='showFormError') Completa los campos requeridos

  b-card(v-else)
    p.font-weight-bold Resumen de venta grupal:
    b-row.mb-5
      b-col(cols='6')
        b-row
          b-col.mb-3(cols='12')
            b-form-row.align-items-center
              b-col(cols='5')
                p.mb-0 Colegio:
              b-col(cols='7')
                p.mb-0.font-weight-bold {{ Institute.name }}

          b-col.mb-3(cols='12')
            b-form-row.align-items-center
              b-col(cols='5')
                p.mb-0 Grado escolar:
              b-col(cols='7')
                p.mb-0.font-weight-bold {{ selectedCategory.name }}

          b-col.mb-3(cols='12')
            b-form-row.align-items-center
              b-col(cols='5')
                p.mb-0 Número de alumnos:
              b-col(cols='7')
                p.mb-0.font-weight-bold {{ emailList.length }}

          b-col.mb-3(cols='12')
            b-form-row.align-items-center
              b-col(cols='5')
                p.mb-0 Comisión:
              b-col(cols='7')
                p.mb-0.font-weight-bold {{ comission }}%

          b-col.mb-3(cols='12')
            b-form-row.align-items-center
              b-col(cols='5')
                p.mb-0 Lista de correos:
              b-col(cols='7')
                p.mb-0.font-weight-bold {{ fileName }}

          //- b-col.mb-3(cols='12')
          //-   b-form-row.align-items-center
          //-     b-col(cols='5')
          //-       p.mb-0 Fecha de vencimiento
          //-     b-col(cols='7')
          //-       p.mb-0.font-weight-bold {{ $moment(expirationDate).format("DD MMMM YYYY") }}

          b-col.mb-3(cols='12')
            b-form-row.align-items-center
              b-col(cols='5')
                p.mb-0 Libro incluido:
              b-col(cols='7')
                p.mb-0.font-weight-bold {{ selectedBook.title }}

          b-col.mb-3(cols='12')
            b-form-row.align-items-center
              b-col(cols='5')
                p.mb-0 Total:
              b-col(cols='7')
                p.mb-0.font-weight-bold ${{ formatMoney(total) }}

      b-col(cols='6')
        b-form-row
          b-col(cols='5')
            p.mb-0 Lista de alumnos:
          b-col(cols='7', style='max-height: 350px; overflow-y: auto')
            p(v-for='email in emailList') {{ email }}

    .text-right
      b-button(variant='gradient-primary', :disabled='loading', @click='generateCode') Confirmar y enviar correos de bienvenida

  full-modal(:visible='successModal', :cancelable='false')
    b-container
      b-row
        b-col.font-weight-bold.text-center
          p La venta grupal ha sido dada de alta correctamente.
          b-button(variant='gradient-primary', :to='{ name: "Escuelas" }') Continuar
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { en, es } from 'vuejs-datepicker/dist/locale'

import Category from '@/parse/models/Category.js'
import Book from '@/parse/models/Book.js'
import Batch from '@/parse/models/Batch.js'
import Institute from '@/parse/models/Institute.js'
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      es: es,
      to: new Date(),
      expirationDate: new Date(),
      selectedCategory: null,
      selectedBook: null,
      categories: [],
      books: [],
      comission: 0,
      comissions: [{ value: 0, text: '0%' }],
      fileName: '',
      emailList: [],
      showResume: false,
      Institute: {},
      successModal: false,
      loading: false,
      showFormError: false
    }
  },
  computed: {
    subtotal() {
      return this.selectedBook ? this.selectedBook.price : 0
    },
    calculatedComission() {
      if (this.subtotal) {
        return this.subtotal * this.comission / 100
      } else {
        return this.comission
      }
    },
    total() {
      return this.subtotal + this.calculatedComission
    }
  },
  methods: {
    shouldShowResume() {
      this.showFormError = false;

      if (this.selectedCategory && this.fileName && this.selectedBook)
        this.showResume = true;
      else this.showFormError = true;
    },
    fileSelected(evt) {
      let obj_csv = null;
      let csvData = [];

      if (evt.target.files && evt.target.files[0]) {
        try {
          let file = evt.target.files[0]
          let reader = new FileReader();
          reader.readAsBinaryString(file);
          reader.onload = (e) => {
            obj_csv = e.target.result

            let lbreak = obj_csv.split("\n");
            lbreak.forEach(res => {
              csvData.push(res.split(",").filter(d => Boolean(d) && d.trim().length).join(''));
            });

            this.emailList = csvData.filter(b => Boolean(b))
            this.fileName = file.name

            this.$bvToast.toast('Se cargó el archivo correctamente.', {
              title: 'Archivo cargado',
              toaster: 'b-toaster-bottom-center',
              solid: true,
              variant: 'success'
            })
          }
        } catch (e) {
          this.$bvToast.toast(`Ocurrió un error cargando el archivo. Por favor intenta de nuevo. (${e.message})`, {
            title: 'Ocurrió un error',
            toaster: 'b-toaster-bottom-center',
            solid: true,
            variant: 'danger'
          })
        }
      } else {
        this.$bvToast.toast('Ocurrió un error cargando el archivo. Por favor intenta de nuevo.', {
          title: 'Ocurrió un error',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          variant: 'danger'
        })
      }
    },
    async loadBooks(value) {
      let res = await Book.load(500, 0, value.id)
      this.books = res.results.map(b => {
        return {
          text: b.title,
          value: {
            id: b.id,
            digitalPhysicalPrice: b.digitalPhysicalPrice,
            title: b.title,
            price: b.physicalPrice
          }
        }
      })
    },
    async generateCode() {
      this.loading = true

      let params = {
        event: "generate",
        type: "group_sale",
        book: this.selectedBook.id,
        institute: this.$route.params.escuela_id,
        users: this.emailList.map(email => email.trim()),
        expiration: this.$moment(this.to).toDate()
      }

      console.log("🚀 ~ file: Maestros.vue ~ line 221 ~ generateCode ~ params", params)

      try {
        let response = await this.$parse.Cloud.run('manageKey', params)
        console.log("🚀 ~ file: VentaGrupal.vue ~ line 293 ~ generateCode ~ response", response)
        this.successModal = true;
      } catch (error) {
        this.showError(`Ocurrió un error creando venta grupal. Por favor intenta de nuevo. (${error.message})`)
      }

      this.loading = false;
    },
  },
  async mounted() {
    for (var i = 5; i <= 100; i += 5) {
      this.comissions.push({
        value: i,
        text: `${i}%`
      });
    }

    let categories = await Category.load()
    for (let category of categories) {
      this.categories.push({
        value: {
          id: category.id,
          name: category.name
        },
        text: category.name
      })
    }

    this.Institute = await Institute.getObject(this.$route.params.escuela_id)
  },
  components: {
    Datepicker
  },
  watch: {
    selectedCategory(value) {
      if (value != null) {
        this.loadBooks(value)
      }
    }
  }
}
</script>

<style>
</style>