import Parse from 'parse'
import moment from 'moment-timezone'

moment.locale('es')
moment.tz.setDefault('America/Mexico_City')

export default class Role extends Parse.Role {

  constructor() {
    super('_Role')
  }

  get name() {
    return this.get('name')
  }

  static getObject(id) {
    return new Parse.Query(this).get(id)
  }

  dump() {
    console.log(this)
  }
}