import Parse from 'parse'

import User from './models/User'
import Book from './models/Book'
import Card from './models/Card'
import Category from './models/Category'
import Institute from './models/Institute'
import Order from './models/Order'
import Role from './models/Role'
import Batch from './models/Batch'

Parse.Object.registerSubclass('_User', User)
Parse.Object.registerSubclass('Book', Book)
Parse.Object.registerSubclass('Card', Card)
Parse.Object.registerSubclass('Category', Category)
Parse.Object.registerSubclass('Institute', Institute)
Parse.Object.registerSubclass('Order', Order)
Parse.Object.registerSubclass('Role', Role)
Parse.Object.registerSubclass('Batch', Batch)

Parse.initialize(process.env.VUE_APP_ID_APP, process.env.VUE_APP_KEY_JS)
Parse.serverURL = process.env.VUE_APP_SERVER_URL

export default Parse
