<template lang="pug">
div
  b-row.justify-content-end
    b-col(cols='auto')
      b-button.text-reset(variant='link', :to='{ name: "Login" }')
        arrow-left-circle-icon
        span.ml-2 Regresar
  validation-observer(v-slot='{invalid, handleSubmit}')
    b-form(@submit.prevent='handleSubmit(doLogin)')
      .login-body-container.d-flex.align-items-center.justify-content-center.flex-column
        b-form-row.mb-5.w-100.justify-content-center
          b-col.text-center.font-weight-bold(cols='4')
            p.mb-0 Escribe tu
            p.mb-0 contraseña
          b-col(cols='4')
            validation-provider(name='contraseña', rules='required|min:1', v-slot='{ errors }')
              b-form-input.w-100.text-center(placeholder='Escribe tu contraseña aquí', v-model='password', :state='errors.length ? false : null', type='password', autofocus)
        .text-center 
          b-button(variant='gradient-primary', :disabled='invalid || loading', type='submit') Continuar
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      password: '',
      loading: false
    }
  },
  methods: {
    ...mapActions(['login']),
    async doLogin() {
      this.loading = true;

      try {
        await this.login({ email: this.$store.state.email, password: this.password })
        this.showSuccess('Bienvenido/a de nuevo.')
        setTimeout(() => {
          this.loading = false;
          this.$router.push({ name: 'Dashboard' })
        }, 1500)
      } catch (error) {
        this.showError(error.message || 'Usuario o contraseña no válidos.')
        this.loading = false;
      }
    }
  },
  mounted() {
    if (!this.$store.state.email) this.$router.push({ name: 'Home' })
  }
}
</script>

<style scoped lang="scss">
.login-body-container {
  width: 100%;
  height: calc(100vh - 56px - 39.8px);
}
</style>