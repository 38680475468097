<template lang="pug">
b-container.py-5
  b-row.mb-3
    b-col
      h5.font-weight-bold Detalle de libro
    b-col(cols='auto')
      b-button.text-reset(variant='link', :to='{ name: "Contenido" }')
        arrow-left-circle-icon
        span.ml-2 Regresar

  b-card(v-if='edit')
    p.font-weight-bold {{ book.name }}
    validation-observer(v-slot='{invalid, handleSubmit}')
      b-form(@submit.prevent='handleSubmit(saveBook)')
        b-row.mb-5
          b-col
            validation-provider(name='nombre', rules='required|min:1', v-slot='{ errors }')
              b-form-group.mb-3(label='Nombre')
                b-form-input(placeholder='Nombre', v-model='book.title', :state='errors[0] ? false : null')
                b-form-invalid-feedback(v-if='errors[0]') {{ errors[0] }}

            validation-provider(name='autor', rules='required|min:1', v-slot='{ errors }')
              b-form-group.mb-3(label='Autor:')
                b-form-input(placeholder='Autor', v-model='book.author', :state='errors[0] ? false : null')
                b-form-invalid-feedback(v-if='errors[0]') {{ errors[0] }}

            validation-provider(name='nivel escolar', rules='required', v-slot='{ errors }')
              b-form-group.mb-3(label='Nivel escolar:')
                b-form-select(:options='categories', v-model='currentCategory', :state='errors[0] ? false : null')
                  template(#first)
                    b-form-select-option(:value='null', disabled) -- Seleccionar --
                b-form-invalid-feedback(v-if='errors[0]') {{ errors[0] }}

            validation-provider(name='precio digital', rules='required', v-slot='{ errors }')
              b-form-group.mb-3(label='Precio digital:')
                b-form-input(type='number', placeholder='Precio digital', v-model.number='book.digitalPrice', :state='errors[0] ? false : null')
                b-form-invalid-feedback(v-if='errors[0]') {{ errors[0] }}

            validation-provider(name='precio físico', rules='required', v-slot='{ errors }')
              b-form-group.mb-3(label='Precio físico:')
                b-form-input(type='number', placeholder='Precio físico', v-model.number='book.physicalPrice', :state='errors[0] ? false : null')
                b-form-invalid-feedback(v-if='errors[0]') {{ errors[0] }}

            validation-provider(name='precio en paquete', rules='required', v-slot='{ errors }')
              b-form-group.mb-3(label='Precio en paquete:')
                b-form-input(type='number', placeholder='Precio en paquete', v-model.number='book.digitalPhysicalPrice', :state='errors[0] ? false : null')
                b-form-invalid-feedback(v-if='errors[0]') {{ errors[0] }}

          b-col
            b-form-row.mb-5
              b-col(cols='4')
                p Portada:
              b-col(cols='8')
                .text-center
                  b-button.mb-2(variant='light', @click='$refs.coverInput.click()', block, size='sm', :disabled='uploading') Subir imagen
                    b-spinner.ml-3(small, variant='primary', v-if='uploading')
                  b-img(:src='book.id ? book.cover : book.cover && book.cover.url ? book.cover.url() : null', :blank='!book.cover', blank-color='#f2f2f2', rounded, fluid, style='max-height: 300px; min-height: 100px')
                  input(type='file', style='display: none', ref='coverInput', accept='image/*', @change='imagePicked')

            b-form-row
              b-col(cols='4')
                p Archivo:
              b-col
                .text-center(v-if='fileProgress == 0')
                  .mb-3
                    input(type='file', style='display: none', ref='fileInput', @change='filePicked')
                    b-button(v-if='book.id', variant='light', @click='$refs.fileInput.click()', size='sm', block) Subir archivo
                    p.text-secondary(v-else) Podrás subir el archivo después de guardar por primera vez el libro
                .upload-progress(v-else)
                  b-progress.mt-2(:max='100', :value='fileProgress', show-progress, animated)
                  small Cargando archivo, por favor no cierres ni actualices esta página

        b-row.align-items-center
          b-col
            b-form-checkbox(v-model='book.enabled', switch) Mostrar en tienda
          b-col.text-right
            b-button(pill, variant='primary', type='submit', :disabled='invalid || fileProgress != 0') Guardar

  b-card(v-else)
    p.font-weight-bold {{ book.name }}
    b-row.mb-5
      b-col
        p.mb-4 Nombre:
          span.font-weight-bold.ml-2 {{ book.title }}
        p.mb-4 Autor:
          span.font-weight-bold.ml-2 {{ book.author }}
        p.mb-4 Nivel escolar:
          span.font-weight-bold.ml-2 {{ book.category ? book.category.name : null }}
        p.mb-4 Precio digital:
          span.font-weight-bold.ml-2 {{ book.digitalPrice }}
        p.mb-4 Precio físico:
          span.font-weight-bold.ml-2 {{ book.physicalPrice }}
        p.mb-4 Precio en paquete:
          span.font-weight-bold.ml-2 {{ book.digitalPhysicalPrice }}
        p Total de ventas:
          span.font-weight-bold.ml-2 {{ saleCount }}

      b-col
        b-form-row.mb-3
          b-col(cols='4')
            p Portada:
          b-col(cols='8')
            .text-center
              b-img(:src='book.cover', :blank='!book.cover', blank-color='#f2f2f2', rounded, fluid, style='max-height: 300px')

        b-form-row
          b-col(cols='4')
            p Archivo:
          b-col
            .text-center
              .mb-3
                b-link.text-reset.text-underline(v-if='book.isFileReady') Archivo subido
                span.text-secondary(v-else-if='book.needsFileProcessing') El archivo está siendo procesado, esta operación puede tardar hasta 10 minutos
                span.text-secondary(v-else) No has subido el archivo

    b-row.align-items-center
      b-col
        b-form-checkbox(v-model='book.enabled', switch, :disabled="!editBook") Mostrar en tienda
      b-col.text-right
        b-button(pill, variant='light', @click='editBook = true') Editar

  //- b-row.align-items-center
  //-   p {{ book }}
  //-   p {{ currentCategory }}
</template>

<script>
import Book from '@/parse/models/Book'
import Order from '@/parse/models/Order'
import Category from '@/parse/models/Category'
import Parse from 'parse'

import fs from 'chrome-fs'
import request from 'request'

import { strg } from '@/firebase'

export default {
  data() {
    return {
      showInStore: true,
      book: {
        title: '',
        author: '',
        digitalPrice: '',
        physicalPrice: '',
        digitalPhysicalPrice: '',
        enabled: true
      },
      saleCount: 0,
      categories: [],
      editBook: false,
      currentCategory: null,
      uploading: false,
      fileProgress: 0
    }
  },
  computed: {
    edit() {
      return this.$route.params.libro_id == 'new' || this.editBook
    }
  },
  methods: {
    imagePicked(e) {
      this.uploading = true
      if (e.target.files.length) {
        let parseFile = new Parse.File(e.target.files[0].name, e.target.files[0])
        parseFile.save().then(file => {
          this.book.cover = file
          this.uploading = false
        }).catch(e => {
          this.$bvToast.toast(`Ocurrió un error cargando carátula de libro. (${e.message})`, {
            title: 'Ocurrió un error',
            toaster: 'b-toaster-bottom-center',
            solid: true,
            variant: 'danger'
          })
          this.uploading = false
        })
      }
    },
    async filePicked(e) {
      let file = e.target.files[0]
      console.log("🚀 ~ file: Libro.vue ~ line 181 ~ filePicked ~ file", file)

      if (file) {
        let extension = file.name.split('.').slice(-1)[0]
        let fileName = this.book.title.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/ /g, '_') + '.' + extension

        console.log("🚀 ~ file: Libro.vue ~ line 185 ~ filePicked ~ fileName", fileName)

        try {
          var storageRef = strg.ref()
          var bookRef = storageRef.child('temp/' + fileName)

          var uploadTask = bookRef.put(file)

          uploadTask.on('state_changed', (snapshot) => {
            var progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.fileProgress = progress
            if (progress == 100) this.fileProgress = 0
          },
            error => {
              console.log(error)
              this.fileProgress = 0
            },
            () => {
              console.log('File uploaded correctly')
              this.enqueueBook()
            })
        } catch (e) {
          console.log(e.message);
        }
      }
    },
    async getBook() {
      try {
        this.book = await Book.getObject(this.$route.params.libro_id)
        this.saleCount = await Order.loadCountByBook(this.$route.params.libro_id)
        this.currentCategory = this.book.category ? this.categories.find(cat => cat.value.id == this.book.category.id).value : null
      } catch (e) {
        this.$bvToast.toast(`Ocurrió un error cargando el libro. (${e.message})`, {
          title: 'Ocurrió un error',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          variant: 'danger'
        })

        setTimeout(() => {
          this.$router.push({ name: 'Contenido' })
        }, 1500)
      }
    },
    async saveBook() {
      if (this.$route.params.libro_id == 'new') {
        try {
          let newBook = new Book()

          await newBook.save({
            ...this.book,
            category: this.currentCategory
          })

          this.$bvToast.toast(`Libro guardado con éxito`, {
            title: 'Guardado',
            toaster: 'b-toaster-bottom-center',
            solid: true,
            variant: 'success'
          })

          setTimeout(() => {
            this.$router.push({ params: { libro_id: newBook.id } })
          }, 1500)
        } catch (error) {
          this.$bvToast.toast(`Ocurrió un error guardando el libro. (${e.message})`, {
            title: 'Ocurrió un error',
            toaster: 'b-toaster-bottom-center',
            solid: true,
            variant: 'danger'
          })
        }
      } else {
        try {
          await this.book.save({
            category: this.currentCategory
          })

          this.$bvToast.toast(`Libro guardado con éxito`, {
            title: 'Guardado',
            toaster: 'b-toaster-bottom-center',
            solid: true,
            variant: 'success'
          })

          this.editBook = false;
        } catch (error) {
          this.$bvToast.toast(`Ocurrió un error guardando el libro. (${e.message})`, {
            title: 'Ocurrió un error',
            toaster: 'b-toaster-bottom-center',
            solid: true,
            variant: 'danger'
          })
        }
      }
    },
    async enqueueBook() {
      this.book = await Parse.Cloud.run('enqueueFile', { book: this.book.id })
    }
  },
  async mounted() {
    let categories = await Category.load()
    this.categories = categories.map(category => ({
      value: category,
      text: category.name
    }))

    if (this.$route.params.libro_id && this.$route.params.libro_id != 'new') {
      this.getBook()
    }
  },
  watch: {
    '$route'(value) {
      this.getBook()
    }
  }
}
</script>

<style>
</style>