<template lang="pug">
  b-container.py-5
    b-row.mb-3
      b-col
        h5.font-weight-bold Escuelas

    b-card 
      p.font-weight-bold Agregar nueva escuela
      validation-observer(v-slot="{invalid, handleSubmit}")
        b-form(@submit.prevent="handleSubmit(createInstitute)")
          b-row
            b-col
              b-form-row.align-items-center.mb-3
                b-col(cols="auto")
                  span Nombre:
                b-col
                  validation-provider(name="nombre", rules="required|min:1", v-slot="{ errors }")
                    b-form-input(placeholder="Escribe aquí el nombre de la escuela", v-model="name", :state="errors[0] ? false : null")
              //- b-form-row.align-items-center
              //-   b-col(cols="auto")
              //-     span Contacto:
              //-   b-col
              //-     validation-provider(name="nombre", rules="required|min:1", v-slot="{ errors }")
              //-       b-form-input(placeholder="Escribe aquí el contacto de la escuela", :state="errors[0] ? false : null")
            b-col
              //- b-form-row.align-items-center.mb-3
              //-   b-col(cols="4")
              //-     span Comisión por venta:
              //-   b-col
              //-     b-form-select(:options="comissions", v-model="comission")
          .text-right
            b-button(variant="gradient-primary", type="submit", :disabled="loading || invalid") Registrar escuela

    b-card 
      p.font-weight-bold Compras
      b-table.card-table(hover, :fields="fields", :items="institutes", responsive, fixed, show-empty)
        template(#empty)
          span.text-secondary No hay datos para mostrar
        template(#cell(name)="{item}")
          b-button.text-reset.font-weight-bold.text-underline(variant="link", :to="{name: 'Escuela', params: {escuela_id: item.id} }") {{ item.name }}
        template(#cell(contact)="{item}")
          b-link(:href="`mailto:${item.contact}`").font-weight-bold {{ item.contact }}
        template(#cell(actions)="{item}")
          b-button.text-reset.font-weight-bold.text-underline(variant="link", :to="{name: 'Escuelas/VentaGrupal', params: {escuela_id: item.id} }") Nueva venta grupal
</template>

<script>
import Institute from '@/parse/models/Institute'

export default {
  data () {
    return {
      name: '',
      comission: 0,
      loading: false,
      comissions: [{value: 0, text: '0%'}],
      fields: [
        {
          key: 'name',
          label: 'Nombre'
        },
        'actions'
      ],
      institutes: []
    }
  },
  mounted () {
    for (var i = 5; i <= 100; i += 5) {
      this.comissions.push({
        value: i,
        text: `${i}%`
      });
    }

    this.loadInstitutes()
  },
  methods: {
    async loadInstitutes () {
      let institutes = await Institute.load(this.paginationLimit, this.paginationLimit * (this.paginationPage - 1), { orderFilter: this.orderFilter || 'descending' })
      this.institutes = institutes.results
    },
    async createInstitute () {
      let institute = new Institute()
      institute.set('name', this.name)

      institute.save().then(institute => {
        this.$bvToast.toast(`Instituto creado correctamente.`, {
          title: 'Creado',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          variant: 'success'
        })

        this.name = ''
        this.loadInstitutes()
      }).catch(e => {
        this.$bvToast.toast(`Ocurrió un error creando instituto. Por favor intenta de nuevo. (${e.message})`, {
          title: 'Ocurrió un error',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          variant: 'danger'
        })
      })
    }
  },
}
</script>

<style>

</style>