var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"py-5"},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('h5',{staticClass:"font-weight-bold"},[_vm._v("Escuelas")])])],1),_c('b-card',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Agregar nueva escuela")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createInstitute)}}},[_c('b-row',[_c('b-col',[_c('b-form-row',{staticClass:"align-items-center mb-3"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v("Nombre:")])]),_c('b-col',[_c('validation-provider',{attrs:{"name":"nombre","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Escribe aquí el nombre de la escuela","state":errors[0] ? false : null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1)],1)],1),_c('b-col')],1),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"gradient-primary","type":"submit","disabled":_vm.loading || invalid}},[_vm._v("Registrar escuela")])],1)],1)]}}])})],1),_c('b-card',[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Compras")]),_c('b-table',{staticClass:"card-table",attrs:{"hover":"","fields":_vm.fields,"items":_vm.institutes,"responsive":"","fixed":"","show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('span',{staticClass:"text-secondary"},[_vm._v("No hay datos para mostrar")])]},proxy:true},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"text-reset font-weight-bold text-underline",attrs:{"variant":"link","to":{name: 'Escuela', params: {escuela_id: item.id} }}},[_vm._v(_vm._s(item.name))])]}},{key:"cell(contact)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{staticClass:"font-weight-bold",attrs:{"href":("mailto:" + (item.contact))}},[_vm._v(_vm._s(item.contact))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticClass:"text-reset font-weight-bold text-underline",attrs:{"variant":"link","to":{name: 'Escuelas/VentaGrupal', params: {escuela_id: item.id} }}},[_vm._v("Nueva venta grupal")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }