<template lang="pug">
  b-container.py-5
    b-row.mb-3
      b-col
        h5.font-weight-bold Detalle de usuario
      b-col(cols="auto")
        b-button.text-reset(variant="link", :to="{name: 'Usuarios'}")
          arrow-left-circle-icon
          span.ml-2 Regresar

    b-card 
      p.font-weight-bold {{ user.name }}
      b-row
        b-col
          p Antigüedad:
            span.font-weight-bold.ml-2  {{ $moment(user.antiquity).format("DD/MM/YY") }}
          p Correo:
            span.font-weight-bold.ml-2  {{ user.email }}
        b-col
          p Número:
            span.font-weight-bold.ml-2  {{ user.phone }}
      .text-right
        b-button(variant="danger", pill, @click="disableUser($route.params.usuario_id)") Dar de baja

    b-card 
      p.font-weight-bold Compras
      b-table.card-table(hover, :fields="fields", :items="orders", responsive, show-empty)
        template(#empty)
          span.text-secondary No hay datos para mostrar
        template(#cell(createdAt)="{item}")
          span.font-weight-bold {{ item.createdAt }}
        template(#cell(total)="{item}")
          span.font-weight-bold {{ item.total }}
        template(#cell(status)="{item}")
          span.font-weight-bold {{ item.status }}
</template>

<script>
import Parse from 'parse'
import Usuario from '@/parse/models/User'

export default {
  data () {
    return {
      fields: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'package',
          lable: 'Formato'
        },
        {
          key: 'subtotal',
          lable: 'Subtotal'
        },
        {
          key: 'shippingCost',
          lable: 'Envío'
        },
        {
          key: 'total',
          label: 'Total'
        },
      ],
      orders: [],
      user: {},
    }
  },
  methods: {
    getUserDetail () {
      Parse.Cloud.run('userDetail', {
          "user": this.$route.params.usuario_id
      }).then(res => {
        this.user = res
        this.orders = res.orders
      }).catch(e => {
        this.$bvToast.toast(`Ocurrió un obteniendo usuario. Por favor intenta de nuevo. (${e.message})`, {
          title: 'Ocurrió un error',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          variant: 'danger'
        })
      })
    },
    disableUser (id, enabled = false) {
      console.log(id);
      Parse.Cloud.run('enabledUser', {
          "user": id,
          enabled: enabled
      }).then(res => {
        if (res = 'User updated success!!') {
          this.$bvToast.toast(`Se actualizó el usuario con éxito.`, {
            title: 'Actualizado',
            toaster: 'b-toaster-bottom-center',
            solid: true,
            variant: 'success'
          })
        } else {
          this.$bvToast.toast(`Ocurrió un actualizando usuario. Por favor intenta de nuevo. (${e.message})`, {
            title: 'Ocurrió un error',
            toaster: 'b-toaster-bottom-center',
            solid: true,
            variant: 'danger'
          })
        }
      }).catch(e => {
        this.$bvToast.toast(`Ocurrió un error deshabilitando usuario. Por favor intenta de nuevo. (${e.message})`, {
          title: 'Ocurrió un error',
          toaster: 'b-toaster-bottom-center',
          solid: true,
          variant: 'danger'
        })
      })
    },
  },
  mounted () {
    this.getUserDetail()
  }
}
</script>

<style>

</style>