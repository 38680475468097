import Vue from 'vue'
import Vuex from 'vuex'

import Parse from 'parse'
import User from '@/parse/models/User'
import Role from '@/parse/models/Role'

Vue.use(Vuex)

let user = Parse.User.current()

export default new Vuex.Store({
  state: {
    user,
    email: '',
    password: ''
  },
  getters: {
    isAdmin: state => () => {
      return state.user && state.user.roleName == 'admin'
    },
    isEditor: state => () => {
      return state.user && state.user.roleName == 'editor'
    }
  },
  actions: {
    async login({ commit }, params) {
      try {
        let user = await User.logIn(params.email, params.password)
        commit('setUser', user)
        return user
      } catch (e) {
        throw `Usuario o contraseña incorrectos: ${e.message}`
      }
    },
    async logout({ commit }, params) {
      try {
        await Parse.User.logOut()
        commit('logout')
      } catch (e) {
        throw `Error cerrando sesión: ${e.message}`
      }
    },
  },
  mutations: {
    setEmail(state, value) {
      state.email = value
    },
    setPassword(state, value) {
      state.password = value
    },
    setUser(state, user) {
      state.user = user
    },
    logout(state) {
      state.user = null
    },
  },
  modules: {
  }
})
