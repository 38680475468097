<template lang="pug">
  b-container.py-5
    b-row.mb-3
      b-col(cols="8")
        h5.font-weight-bold Contenido
      b-col(cols="4")
        b-form-row.align-items-center
          b-col(cols="auto")
            span Nivel escolar:
          b-col
            b-form-select(:options="categories", v-model="filterGrade", @change="loadBooks")
              template(#first)
                b-form-select-option(:value="null") Todos

    b-row 
      b-col(cols="3")
        b-card(no-body, style="border-radius:15px;max-width:172px;cursor:pointer;", @click="$router.push({name: 'Libro', params: {libro_id: 'new'}})")
          b-img(blank, blank-color="#ccc", height="220", rounded, style="width: 100%;")
          .p-3.text-center
            p.font-weight-bold Agregar nuevo
            p.mb-0.text-sm  
              plus-circle-icon
      b-col(cols="3", v-for="(book, index) in books", :key="index")
        b-card(no-body, style="border-radius:15px;max-width:172px;cursor:pointer;", @click="$router.push({name: 'Libro', params: {libro_id: book.id}})")
          b-img(:src="book.cover", :blank="!book.cover", blank-color="#f2f2f2", fluid, style="border-top-left-radius: 15px;border-top-right-radius: 15px;")
          .p-3
            p.font-weight-bold {{ book.title }}
            p.mb-0.text-sm {{ book.isbn }}
</template>

<script>
import Book from '@/parse/models/Book'
import Category from '@/parse/models/Category'

export default {
  data () {
    return {
      filterGrade: null,
      books: [],
      categories: []
    }
  },
  methods: {
    async loadBooks () {
      let books = await Book.load(this.paginationLimit, this.paginationLimit * (this.paginationPage - 1), this.filterGrade ? this.filterGrade.id : null)
      this.books = books.results
    },

  },
  async mounted () {
    this.loadBooks()

    let categories = await Category.load()
    for (let category of categories) {
      this.categories.push({
        value: {
          id: category.id,
          name: category.name
        },
        text: category.name
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>