<template lang="pug">
b-container.py-5
  h5.mb-3.font-weight-bold Ventas
  b-row.mb-3(v-if='isAuth')
    b-col(cols='6')
      b-card
        b-form-row
          b-col(cols='6')
            p.mb-2.font-weight-bold Ventas totales: {{ balance_total.countOf || 0 }}
            p.mb-3 Ingresos totales ${{ formatMoney(balance_total.quantity) || 0 }}

          b-col(cols='6')
            p.mb-2.font-weight-bold Ventas esta semana: {{ balance_week.countOf || 0 }}
            p.mb-3 Ingresos totales ${{ formatMoney(balance_week.quantity) || 0 }}

          b-col(cols='6')
            p.mb-2.font-weight-bold Ventas este mes: {{ balance_month.countOf || 0 }}
            p.mb-0 Ingresos totales ${{ formatMoney(balance_month.quantity) || 0 }}
        .text-right.pt-3
          b-button(variant='gradient-primary', :to='{ name: "Ventas/History" }') Ver historial completo
    b-col(cols='6')
      b-card
        p.mb-2.font-weight-bold Descargar reporte de ventas
        p Selecciona el periodo:
        b-form-row 
          b-col.d-flex.align-items-center.justify-content-center(cols='6')
            datepicker(v-model='from', input-class='form-control', :bootstrap-styling='true', :language='es')
            span.ml-2 a
          b-col(cols='6')
            datepicker(v-model='to', input-class='form-control', :bootstrap-styling='true', :language='es', :disabled-dates='{ from: limitDate }')
        .text-right.pt-3
          b-button(variant='gradient-primary', @click='exportReport') Descargar
  b-card 
    p.font-weight-bold Nuevas ventas (pendientes de envío)
    b-table.card-table.mb-0(hover, :fields='fields', :items='orders', responsive, show-empty)
      template(#empty)
        p.text-center.mb-0.text-secondary No hay datos para mostrar
      template(#cell(user)='{item}')
        b-link.font-weight-bold(:href='`mailto:${item.user}`') {{ item.user }}
      template(#cell(createdAt)='{item}')
        span.font-weight-bold {{ item.createdAt }}
      template(#cell(actions)='{item}')
        b-button.text-reset.font-weight-bold.text-underline(variant='link', @click='orderToAssign = item; showAssignModal = true') Asignar guía
  b-pagination(v-model='paginationPage', :total-rows='paginationCount', :per-page='paginationLimit', @change='loadOrders', v-if='!paginationLoading')

  full-modal(:visible='showAssignModal', @cancel='showAssignModal = false')
    b-container
      validation-observer(v-slot='{invalid, handleSubmit}')
        b-form-row.mb-5.justify-content-center
          b-col.text-center.font-weight-bold(cols='5')
            p.mb-0 Asignar número de guía
            p.mb-0 para rastreo de paquete:
          b-col(cols='5')
            validation-provider(name='número de guía', rules='required|min:1', v-slot='{ errors }')
              b-form-input.w-100.text-center.mb-2(placeholder='Introduce el número de guía aquí', type='email', v-model='trackingGuide', :state='errors.length ? false : null')
            .text-center.font-weight-bold.text-sm Recuerda verificar el número
        .text-center 
          b-button(variant='gradient-primary', :disabled='invalid', @click='assignGuide') Asignar

  full-modal(:visible='successModal', :cancelable='false')
    b-container
      b-row
        b-col.font-weight-bold.text-center
          p El número de guía se ha asignado correctamente.
          p Hemos enviado al usuario un correo con el detalle del número de guía asignado.
          b-button(variant='gradient-primary', @click='successModal = false; loadOrders()') Continuar
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/dist/locale'

import Order from '@/parse/models/Order'

import fileDownload from 'js-file-download'

import Parse from 'parse'

export default {
  data() {
    return {
      balance_week: {},
      balance_month: {},
      balance_total: {},
      es: es,
      limitDate: new Date(),
      showAssignModal: false,
      successModal: false,
      from: new Date(),
      to: new Date(),
      months: [],
      fields: [
        {
          key: 'createdAt',
          label: 'Fecha'
        },
        {
          key: 'book.title',
          label: 'Libro'
        },
        {
          key: 'user.name',
          label: 'Usuario'
        },
        {
          key: 'address',
          label: 'Dirección'
        },
        {
          key: 'packageString',
          label: 'Formato'
        },
        {
          key: 'status',
          label: 'Estatus'
        },
        {
          key: 'actions',
          label: 'Acciones'
        },
      ],
      orders: [],
      trackingGuide: '',
      totalCount: 0,
      totalWeekCount: 0,
      totalMonthCount: 0,
      orderToAssign: null
    }
  },
  computed: {
    isAuth() {
      // el fetchedUser es un pequeño hack que describiré 
      // paso a paso en mi libro reactividad for dummies 👌
      return Boolean(Parse.User.current() && Parse.User.current().role.get('name') == 'admin') && this.fetchedUser == false ? true : true
    }
  },
  methods: {
    generateMonths() {
      this.months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
        .map((e, index) => ({ value: index, text: e }))
    },
    async loadOrders() {
      let orders = await Order.load(this.paginationLimit, this.paginationLimit * (this.paginationPage - 1))
      this.orders = orders.results
      this.totalCount = orders.count
    },
    async loadTotals() {
      let weekOrders = await Order.load(10000, 0, { from: this.$moment().startOf('week').toDate(), to: this.$moment().endOf("day").toDate() })
      let monthOrders = await Order.load(10000, 0, { from: this.$moment().startOf('month').toDate(), to: this.$moment().endOf("day").toDate() })

      this.totalWeekCount = weekOrders.count
      this.totalMonthCount = monthOrders.count
    },
    async assignGuide() {
      try {
        await Parse.Cloud.run('assignTrackingGuide', {
          order: this.orderToAssign.id,
          trackingGuide: this.trackingGuide
        })

        this.orderToAssign = null;
        this.showAssignModal = false;
        this.successModal = true;
      } catch (error) {
        this.showError(`Ocurrió un error asignando guía. Por favor intenta de nuevo. (${error.message})`)
      }
    },
    async exportReport() {
      try {
        let blob = await this.$reports.balancePeriod(this.from, this.to)
        fileDownload(new Blob([blob]), 'reporte_ventas_periodo.xlsx')
      } catch (error) {
        this.showError(`Ocurrió un error descargando el reporte. Por favor intenta de nuevo. (${error.message})`)
      }
    }
  },
  async mounted() {
    this.generateMonths();
    this.loadOrders()
    this.loadTotals()

    try {
      let balance = await Parse.Cloud.run('balance')
      Object.assign(this, balance)
    } catch (error) {
      this.showError(`No fue posible cargar el balance: ${error.message}`)
    }
  },
  components: {
    Datepicker
  }
}
</script>

<style lang="scss" scoped>
</style>